import React from "react";
import "../Notify/notify.scss";

function Notify() {
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state }),
    })
      .then(() => alert("Success!"))
      .catch((error) => alert(error));

    e.preventDefault();
  };

  return (
    <div id="notify">
      <input className="c-checkbox" type="checkbox" id="checkbox" />
      <div className="c-formContainer">
        {/* <form class="c-form" action="https://formsubmit.co/support@aflaatoon.in" method="POST"> */}
        {/* <form class="c-form" name="notify_list" method="post" data-netlify="true" > */}
        <form className="c-form" onSubmit={handleSubmit}>
          {/* <input type="hidden" name="form-name" value="notify_list" /> */}

          <input
            className="c-form__input"
            name="email"
            placeholder="E-mail"
            type="email"
            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$"
            required
          />
          <label className="c-form__buttonLabel" htmlFor="checkbox">
            <button className="c-form__button" type="submit">
              Notify
            </button>
          </label>
          <label
            className="c-form__toggle"
            for="checkbox"
            data-title="Notify me"
          ></label>
        </form>
      </div>
    </div>
  );
}

export default Notify;

import React from 'react'
import './social_link.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'//importing font awesome component
// import {faFacebookSquare} from '@fortawesome/free-brands-svg-icons'//importing font awesome icons
import {faInstagramSquare} from '@fortawesome/free-brands-svg-icons'
// import {faTwitter} from '@fortawesome/free-brands-svg-icons'
import {faLinkedin} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function SocialLinks() {
  return (
    <div className="content-footer">
    <p>Follow us here for more updates</p>
    <ul className="social">
        <li><a className="css-is-deranged" href="https://www.instagram.com/yoursocialikigai/" target="_blank"
               rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagramSquare}></FontAwesomeIcon></a></li>
        {/*<li><a className="css-is-deranged" href="https://www.facebook.com/" target="_blank"*/}
        {/*        rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookSquare}></FontAwesomeIcon></a></li>*/}
        {/*<li><a className="css-is-deranged" href="https://twitter.com/" target="_blank"*/}
        {/*        rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon></a></li>*/}
        <li><a className="css-is-deranged" href="https://www.linkedin.com/company/yoursocialikigai/" target="_blank"
                rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon></a></li>
    </ul>
    <p className="copyright">Copyright © 2023 YourSocialIkigai.com - All Rights Reserved.</p>
    <div className='email-address'>
        <label form="email" className="email-label">Email <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> : </label>
        <a className="css-is-deranged" href="mailto:design@yoursocialikigai.com" target="_blank"
                    rel="noopener noreferrer">design@yoursocialikigai.com</a>
    </div>
</div>
  )
}

export default SocialLinks
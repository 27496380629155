// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import React from "react";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown"; //clock
import "@leenguyen/react-flip-clock-countdown/dist/index.css"; //clock css
import VideoBackground from "../src/assets/Starry Night.mp4"; //video
import "./style.css"; //css
import Notify from "./components/Notify/Notify";
import {Helmet} from "react-helmet";
import SocialLinks from "./components/SocialLinks/SocialLinks";

function App() {
    return (
        <section className="page">
            {/* <h1>Flip Clock Countdown</h1>
      <FlipClockCountdown
        date="2021-12-31T00:00:00"
        size="large"
        color="red"
        backgroundColor="black"
        showSecond={false}
        showMillisecond={false}
      /> */}

            <Helmet>

                {/*Title tag: The title of your web page*/}
                <title>YourSocialIkigai - Digital Marketing, Brand Development and Website Development Firm</title>

                {/*Meta description tag: A brief summary of your web page content */}
                <meta name="description"
                      content="YourSocialIkigai is a digital marketing, brand development and website development firm that helps you create and grow your online presence and business."/>

                {/*Meta keywords tag: A list of keywords related to your web page content*/}
                <meta name="keywords"
                      content="digital marketing, brand development, website development, social media marketing, SEO, web design, web development"/>

                {/*Meta robots tag: A directive to search engines on how to crawl and index your web page*/}
                <meta name="robots" content="index,follow"/>

                {/*Meta viewport tag: A directive to web browsers on how to adjust the web page size and scale to fit different devices*/}
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

                {/*Meta charset tag: The character encoding of your web page */}
                <meta charSet="utf-8"/>
            </Helmet>

            {/* overlay  */}
            <div className="overlay"></div>
            {/* video  */}
            <video src={VideoBackground} autoPlay muted loop></video>

            {/* content */}
            <div className="page-content">
                <h1>Spawning Soon</h1>
                <h3>Drop your Email and we'll let you know when our site goes live.</h3>
                {/* Clock  */}

                <FlipClockCountdown
                    className="clock-work"
                    // to={new Date().getTime() + 24 * 3600 * 1000 + 5000}
                    to={"2023-12-01T14:27:32.635Z"}
                >
                    finish
                </FlipClockCountdown>

                <Notify></Notify>
                <SocialLinks></SocialLinks>
                {/* <button className="btn">Notify me</button> */}
            </div>
        </section>
    );
}

export default App;
